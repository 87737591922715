import {useRef} from 'react';
import "./Footer.css"

const Footer = () => {
    return ( 
        <html>
            <head>
            </head>
            <footer className='foot'>
                <div className='foot'>
                    <h2 className='footertxt'>Lady Meek Website Development</h2>
                </div>
            </footer>
        </html>
     );
}
 
export default Footer;